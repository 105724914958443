import { ArticleType } from './types';

const data_2024: { [key: string]: ArticleType } = {
  'tech-certifications': {
    id: 'tech-certifications',
    title: 'AWS SAA + CKA + CKS preparation',
    description: '',
    shortDescription: ' Tips and tricks (Nov)',
    datetime: '24 Nov 2024',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/how-to-build-this-blog/preview.jpg',
    tags: ['aws','kubernetes'],
    timeToRead: 4
  },
  'exploring-istio' : {
    id: 'exploring-istio',
    title: 'Exploring Istio',
    description: 'Istio - connect, secure, control and observe services',
    shortDescription: ' Istio - connect, secure, control and observe services (February)',
    datetime: '25 Feb 2024',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/preview.jpg',
    tags: ['kubernetes'],
    timeToRead: 4
  },
}
const data_2023: { [key: string]: ArticleType } = {
  // 'a-simple-k8s-tutorial' : {
  //   id: 'a-simple-k8s-tutorial',
  //   title: 'A simple Kubernetes tutorial',
  //   description: '2 years of Kubernetes knowledge condensed to 500 words',
  //   shortDescription: ' 2 years of Kubernetes knowledge condensed to 500 words (October)',
  //   datetime: '18 Oct 2023',
  //   image: 'https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/preview.jpg',
  //   tags: ['kubernetes'],
  //   timeToRead: 4
  // },
  'deploying-kubeflow-using-k8s' : {
    id: 'deploying-kubeflow-using-k8s',
    title: 'Deploying Kubeflow using Kubernetes',
    description: "This article discusses the challenges of deploying Kubeflow with Kubernetes, opting for DigitalOcean's droplets and microk8s due to issues with GKE. It outlines setting up a Kubernetes cluster, configuring certmanager, purchasing a domain, and configuring dex and oidc. The article concludes by accessing Kubeflow.",
    shortDescription: ' discusses the challenges of deploying Kubeflow with Kubernetes (October)',
    datetime: '17 Oct 2023',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/deploying-kubeflow-using-k8s/preview.jpg',
    tags: ['kubernetes','self-host','kubeflow'],
    timeToRead: 4
  },
  'deploying-lambda-using-serverless' : {
    id: 'deploying-lambda-using-serverless',
    title: 'Deploying lambda using Serverless',
    description: "This post outlines how to use the Serverless Framework for seamless management and deployment of AWS Lambda functions, allowing automatic scaling, robust availability, and secure integrations with other AWS services.",
    shortDescription: ' outlines how to use the Serverless Framework for seamless management and deployment of AWS Lambda functions (June)',
    datetime: '19 June 2023',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/deploying-lambda-using-serverless/preview.jpg',
    tags: ['aws','serverless'],
    timeToRead: 3
  },
  'gitops-in-k8s-cluster' : {
    id: 'gitops-in-k8s-cluster',
    title: 'GitOps in Kubernetes Cluster',
    description: "This project intends to develop a complete pipeline merging Git methodologies, Continuous Integration (CI), and Continuous Deployment (CD) for infrastructure automation, provisioning, and monitoring - a process known as GitOps. GitOps includes Git ecosystem features like pull requests and code reviews into infrastructure automation. The use of GitOps can enhance an organization's capability to frequently release and rollback features",
    shortDescription: ' demonstrates how to integrate GitOps into Kubernetes (June)',
    datetime: '18 June 2023',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/gitops-in-kubernetes-cluster/preview.jpg',
    tags: ['kubernetes'],
    timeToRead: 3
  },
  'k8s-informer-pattern' : {
    id: 'k8s-informer-pattern',
    title: 'Kubernetes Informer Pattern',
    description: 'Leveraging informer pattern to retrieve and manage Kubernetes resources more effectively.',
    shortDescription: ' leverages informer pattern to retrieve and manage Kubernetes resources more effectively (June)',
    datetime: '17 June 2023',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/k8s-informer-pattern/preview.jpg',
    tags: ['kubernetes','golang'],
    timeToRead: 3
  },
  'how-to-build-this-blog': {
    id: 'how-to-build-this-blog',
    title: 'How did I build this blog',
    description: 'Welcome to the initial blog post, designed to share insights on topics such as DevOps, Cloud, and Rust. The first entry delves into the creation of the blog itself, utilizing tools like React.js, Typescript, Tailwind, ReactMarkdown, and Vercel.',
    shortDescription: ' fmt.Println("Hello World") (May)',
    datetime: '7 May 2023',
    image: 'https://d31ofspcjjwsfk.cloudfront.net/how-to-build-this-blog/preview.jpg',
    tags: ['react','tailwind'],
    timeToRead: 1
  },
}
export {
  data_2023,
  data_2024
}