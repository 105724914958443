import Article from '../components/article';
import Page404 from './page404';
import { useLocation } from 'react-router-dom';
import { content as c1 } from '../assets/gitops-in-k8s-cluster/markdown';
import { content as c2 } from '../assets/how-to-build-this-blog/markdown'; 
import { content as c3 } from '../assets/k8s-informer-pattern/markdown';
import { content as c4 } from '../assets/deploying-lambda-using-serverless/markdown';
import { content as c5 } from '../assets/deploying-kubeflow-using-k8s/markdown';
import { content as c6 } from '../assets/a-simple-k8s-tutorial/markdown';
import { content as c7 } from '../assets/exploring-istio/markdown';
import { content as c8 } from '../assets/tech-certifications/markdown';


const renderTemplate = (key : string) : string => {
  switch (key) {
    case 'gitops-in-k8s-cluster':
      return c1
    case 'how-to-build-this-blog':
      return c2
    case 'k8s-informer-pattern':
      return c3
    case 'deploying-lambda-using-serverless':
      return c4
    case 'deploying-kubeflow-using-k8s':
      return c5
    case 'a-simple-k8s-tutorial':
      return c6
    case 'exploring-istio':
      return c7
    case 'tech-certifications':
        return c8
    default:
      return ''
  }
}

const ArticlePage = () => {
  const location = useLocation();
  const key = location.pathname.slice(1)
  const content = renderTemplate(key)
  if (!content) {
    return <Page404/>
  }
  return (
    <div className='flex justify-start flex-col relative flex-1 overflow-hidden'>
      <Article markdown={content}/>
    </div>
  );
}

export default ArticlePage;
