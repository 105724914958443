export const content = `
# How I earned 4 tech certificates in a year

![Kuala Lumpur](https://d31ofspcjjwsfk.cloudfront.net/tech-certifications/background.jpeg)

I has been awhile since I have written. Over the past year, I have obtained 4 certifications namely

1. AWS Certified Solution Architect - Associate (SAA-C03)
2. AWS Certified Solution Architect - Professional
3. Certified Kubernetes Assiociate (CKA)
4. Certified Kubernetes Security Specialise (CKS)

I will be sharing my experience with each certification, how much time I spend preparing and what are some useful resources.

## AWS Certified Solution Architect - Associate (SAA-C03)

### My experience
This certification was a great opportunity for me to deepen my understanding of AWS services and how they interact with each other. 
Before taking it, I already had about a year of hands-on experience with AWS, mostly from a developer's perspective. 

Preparing for this certification helped me learned a wider range of AWS services and gain a broader perspective. 
I spent three weeks preparing, dedicating around 10 hours each week. It was challenging at times, but with consistent practice, it felt manageable and ultimately very rewarding.

If you are taking the exam online, make sure you clear your table and have a good internet connection.

### Useful resources
I mainly used Stephane Maarek and Adrian Cantrill courses, here are some links

1. [AWS Certified Solution Architect Associate Udemy Course](https://www.udemy.com/course/aws-certified-solutions-architect-associate-saa-c03)
1. [AWS Certified Solution Architect Associate Udemy Pracice Exams](https://www.udemy.com/course/practice-exams-aws-certified-solutions-architect-associate)
2. [Andrian Cantrill Courses](https://learn.cantrill.io/)

Adrian Cantrill's courses were more in depth compared to Stephane Maarek's one. Also, it is extremely important to do the practice exams as the actual exam will be very similar to it.
Do not be too worried if you don't do well for the practice exams, in fact I failed most of them.


## Certified Kubernetes Assiociate (CKA)

### My experience
Right after earning my AWS SAA-C03 certification, I decided to challenge myself with the CKA certification. 
It was definitely on the pricier side, costing around $500, but it came with one free retry and a killer.sh practice session, which was a great bonus. 

What I loved about the CKA was how hands-on it was—solving real challenges directly through the command line felt so much more engaging compared to the theoretical approach of the AWS SAA-C03. 
It was a refreshing and rewarding experience for me.

The CKA certification is designed for those who want to understand Kubernetes from an administrator's perspective. It commonly includes questions about tasks like restarting services or debugging manifest files on the master node.
As I already had 1.5 years experience working with Kubernetes, this exam was quite straight forward to me. I took about 2 weeks to study for the exam, spedning about 25 hours.

Some quick tips that I have

1. Be familiar with the kubectl documentation
2. Be quick with all your \`kubectl\` commands
3. Make sure you do timed practice


### Useful resources
I mainly did timed lab sessions from these 2 websites. The exam will be similar to this.

1. [https://killer.sh/](https://killer.sh/)
2. [kodekcloud](https://kodekloud.com/learning-path/cka)


## AWS Certified Solution Architect - Professional

### My experience
I wanted to push my limits and earn the AWS Solutions Architect Professional certification as quickly as possible. 
And yes, everything you’ve heard about this certification is true—it’s tough. In my experience, it’s about three times harder than the AWS SAA-C03.
The questions are much longer, there are more of them , and the answer choices often feel frustratingly similar. If the AWS SAA-C03 is like a 5km run, the Solutions Architect Professional feels more like a half marathon!

The content in the AWS Solutions Architect Professional certification builds on the associate-level material but with a stronger focus on networking (e.g VPNs) and organizational setups (AWS Organizations, SCPs, etc.).
I dedicated three months to preparation because I constantly felt that I wasnt' prepared enough.

In my opinion, unless your work require you to have in depth knowledge on AWS, there is not a need to take this certificate.

### Resources
I used the same preparation techniques as I did for the AWS SAA-C03, but this time, I went through all the quizzes twice.

1. [Udemy - Stephan Maarek](https://www.udemy.com/course/practice-exam-aws-certified-solutions-architect-professional)
1. [Udemy - Jon Bonso](https://www.udemy.com/course/aws-solutions-architect-professional-practice-exams-sap-c02)
1. [Udemy - Neal Davis](https://www.udemy.com/course/aws-certified-solutions-architect-professional-aws-practice-exams)

## Certified Kubernetes Security Specialist (CKS)

### My experience
The CKS certification is similar to the CKA but places a stronger emphasis on security tools like Falco, AppArmor, and Docker. I dedicated a month to preparing for the CKS, spending about 10 hours per week on it.

In my experience, this certification is valuable as it introduced me to security concepts in Kubernetes that I was previously unfamiliar with. However, it's worth noting that the certification is quite costly and requires the CKA as a prerequisite.

I believe the CKS is particularly beneficial for engineers working with Kubernetes who aim to implement best practices and enhance security in their workflows.

### Useful resources
I mainly did timed lab sessions from killer.sh and killer shell. Personally the actual questions were more similar to the ones in Killer Shell than killer.sh

1. [https://killer.sh/](https://killer.sh/)
2. [Killer Shell](https://killercoda.com/killer-shell-cks)
3. [kodekcloud](https://kodekloud.com/learning-path/cks)

I hope you found these tips helpful. See you next time!
`;