
function AboutPage() {
  return (
    <div className="flex justify-start flex-col relative flex-1">
      <div className = 'black text-xl mb-2 mt-8 font-semibold'>
        About me
      </div>
      <div className='leading-7 text-xl'>
        I recently graduated and I am currently based in Singapore. I have a keen interest in DevOps and backend development,
        with a particular focus on cloud native solutions. I am familiar with several cloud native tools, such as Kubernetes, Argo and Istio. 
        Additionally, I am currently learning blockchain and Rust. During my free time, I enjoy jogging, watching Kdramas and playing MOBA games.
      </div>
      <div className = 'black text-xl mb-2 mt-8 font-semibold'>
          Contact me
      </div>
      <div className="flex items-center text-text font-i my-1 text-xl">
        <a href='https://github.com/kaikiat'>{'>'} Visit my GitHub profile:<span style = {{'color': '#b7410e'}}>&nbsp;https://github.com/kaikiat </span></a>
      </div>
      <div className="flex items-center text-text font-i my-1 text-xl">
        <a href='https://www.linkedin.com/in/kaikiatpoh'>{'>'} Connect with me:<span style = {{'color': '#b7410e'}}>&nbsp;https://www.linkedin.com/in/kaikiatpoh </span></a>
      </div>
      <div className="flex items-center text-text font-i my-1 text-xl">
        <a href='https://leetcode.com/kaikiat'>{'>'} Sharing my Rust solutions:<span style = {{'color': '#b7410e'}}>&nbsp;https://leetcode.com/kaikiat </span></a>
      </div>
    </div>
  );
}
export default AboutPage;
